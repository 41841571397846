$font-family-sans-serif:      'Oswald', Helvetica, Arial, sans-serif;
$font-family-serif:           'Quattrocento', "Times New Roman", Times, serif;

$body-bg:                     #fffffd;
$body-color:                  #40403d;

$link-color:                  #111108;
$link-decoration:             none;
$link-hover-color:            #111108;
$link-hover-decoration:       none;

$display4-size:               3.75rem;
