html, body, #slide {
   height:100%;
   width: 100%;
   margin: 0;
   padding: 0;
   border: 0;
   font-family: $font-family-serif;
}

h1 {
font-family: $font-family-sans-serif;

}

#slide {
  td {
     vertical-align: middle;
     text-align: center;

     > div {
       display: inline-block;
       padding: 5px;
     }
  }

  img {
    width: 100%;
    max-width: 1000px;
    height: auto;
    margin-bottom: 5px;
  }
}
