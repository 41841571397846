
.dd-container {
  position: relative;
  top: 0;

  .fullpagewrapper {
    overflow: hidden;
    position: relative;

    .handle {

      .slide {
        float: left;
        position: relative;
        overflow: hidden;
        display: inline-block;
        min-height: 100vh;
      }
    }
  }
}
